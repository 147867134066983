<script>import { api, Formrow, Button, IconSpinner, IconSearch } from "duo-kit";
import { createEventDispatcher } from "svelte";
export let user = {};
export let custom = false;
export let countries = [];
let search = {};
let searchResults = [];
let ErrorString = "";
let searchDebounce,
  searching = false;
let dispatch = createEventDispatcher();
async function runSearch(term) {
  let res = await api.save({
    url: `/duocms/api/melissa/suggest`,
    data: {
      country: user.country,
      text: term
    },
    method: "POST"
  });
  searchResults = res.data.Results;
  ErrorString = res.data.ErrorString;
  searching = false;
}
async function loadCountries() {
  countries = (await api.load({
    url: `/duocms/api/countries`
  })).data;
  if (!user.country && navigator.languages) {
    let country = countries.find(c => c.code == navigator.languages[0].split("-")[1]);
    if (country) user.country = country.code;
  }
}
async function addressFromTypeahead(item) {
  user.address = item.Address.DeliveryAddress.split(",").map(str => str.trim()).join("\n");
  if (item.Address.DependentLocality) user.address += "\n" + item.Address.DependentLocality;
  user.city = item.Address.City || item.Address.Locality;
  user.state = user.country == "US" ? item.Address.AdministrativeArea : "";
  user.postcode = item.Address.PostalCode;
  user.country = item.Address.ISO3166_2;
  dispatch("userupdated", JSON.parse(JSON.stringify(user)));
  resetSearch();
  custom = false;
}
function resetSearch() {
  searchResults = [];
  ErrorString = "";
  search.text = "";
}
function customAddress() {
  searchResults = [];
  ErrorString = "";
  search.text = "";
  custom = true;
  dispatch("custom");
}
async function debounce(e) {
  searching = true;
  if (searchDebounce) clearTimeout(searchDebounce);
  if (e.target.value == "") {
    searchResults = [];
    return;
  }
  let term = e.target.value;
  searchDebounce = setTimeout(() => {
    runSearch(term);
  }, 500);
}
$: !countries.length && loadCountries();</script>

<div class="lookup">
  <Formrow type="select" vertical label="Country" required id="country" bind:value={user.country}>
    <option value="">Select Country</option>
    {#each countries as country}
      <option value={country.code} selected={user.country == country.code}>{country.name}</option>
    {/each}
  </Formrow>
  <Formrow label="Type Address" vertical id="searchaddress" placeholder="Type to lookup your address..." type="text" on:input={debounce} bind:value={search.text} >
    <div slot="suffix">
      <Button>{#if searching}<IconSpinner />{:else}<IconSearch />{/if}</Button>
    </div>
  </Formrow>
  {#if !custom}
    <small><a href="#" on:click|preventDefault={customAddress}>Click to add custom address</a></small>
  {/if}
</div>
{#if searchResults.length}
  <div class="typeahead">
    <div class="results">
      {#each searchResults as item}
        <button class="result-item" type="button" on:click={()=> addressFromTypeahead(item)}>{item.Address.Address}</button>
      {/each}
    </div>
  </div>
{:else if ErrorString}
  <div class="typeahead">
    <div class="results">
      <button class="result-item" type="button" on:click={customAddress}>{ErrorString} - click to add custom address</button>
    </div>
  </div>  
{/if}

<style>
  .lookup{
    --alert-color:#fafafb;
    display:flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap:5px;
    --s-button-margins:0;
    --s-formrow-margins:0;
    background:var(--alert-color);
    padding:0 10px 10px 10px;
    border-radius:5px;
    border:1px solid color-mix(in lch, var(--alert-color) 0%, lch(85 20 none))
  }

  .lookup :global(.form-row.vertical){
    /* flex-direction: column;
    margin:0px; */
    --s-labelwidth:100%;
  }

  .typeahead{
    position: relative;
  }
  .results{
    position:absolute;
    left:0px;
    right:0px;
    background:white;
    box-shadow:0 2px 10px rgba(0,0,0,0.3);
    z-index:2;
    overflow:auto;
    max-height:200px;
  }
  .result-item{
    padding:10px;
    border:1px solid #ddd;
    border-width:0px 1px 1px 1px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
  }
  .result-item:hover{
    background:#eee;
  }

  .lookup :global(.form-row){
    flex:1;
    flex-basis:300px;
  }



</style>