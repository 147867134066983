<script>import Formrow from "./formrow.svelte";
import Popover from "./popover.svelte";
import Calendar from "./calendar.svelte";
import CalIcon from "../icons/calendar.svelte";
import { createEventDispatcher } from "svelte";
export let label = "";
export let labelwidth = "150px";
export let name = "";
export let value = "";
export let suggested = "";
export let autocomplete = null;
export let disabled = null;
export let required = null;
export let locale = null;
export let format = "dd/MM/yyyy";
export let utc = false;
export let placement = "left";
export let placeholder = "";
export let mode = "default";
export let id = null;
export let daystyle = () => "";
export let open;
export let vertical;
export let formmode = null;
export let note = null;
import dateFormatter, { parse } from "./dateformatter.js";
import { beforeUpdate, onMount } from "svelte";
let date = value ? new Date(value) : "";
let prevVal = value;
let focussed = false;
let hasError = false;
let dispatch = createEventDispatcher();
let formattedValue = dateFormatter({
  date,
  format,
  locale
});
let calendar;
beforeUpdate(() => {
  date = value ? new Date(value) : "";
  if (!focussed) {
    formattedValue = dateFormatter({
      date,
      format,
      locale
    });
  }
});
const handleBlur = e => {
  focussed = false;
  handleInput(e);
};
const handleInput = e => {
  let newval = e.target.value;
  if (newval == "") {
    value = "";
  } else {
    try {
      value = parse({
        format,
        value: newval,
        utc
      });
      hasError = false;
    } catch (e) {
      hasError = true;
    }
  }
};
function handleChange() {
  if (prevVal != value) {
    dispatch("change", value);
    prevVal = value;
  }
}
$: handleChange(value);</script>


<div class="formdate">
  <Formrow {label} bind:vertical mode={formmode} note={note} value={formattedValue} {name} {id} {labelwidth} {required} {autocomplete} {disabled} {placeholder} on:focus={(e)=> focussed = true } on:blur={handleBlur} on:input={handleInput} type="text">
    <span slot="suffix">
      <Popover placement={placement} bind:open={open} mode={hasError ? "danger": mode}>
        <CalIcon />
        <div slot="content">
          <Calendar bind:value={value} locale={locale} bind:suggested={suggested} {utc} daystyle={daystyle}  />
        </div>
      </Popover>
    </span>
  </Formrow>
</div>

<style>
  :global(.formdate .form-row .form-control){
    border-radius:4px 0 0 4px;
    border-right-width: 0px;
  }
</style>