<script context="module">import page from "page.js";
export const key = {};
export function navigate(path) {
  let url = new URL(path, window.location); // makes relative paths work
  page.show(url.pathname + (url.search ? url.search : ""));
}
window.addEventListener('pageshow', event => {
  if (event.persisted) {
    page(); // make page re-render when the back button is used from a non-client route
  }
});</script>

<script>export let basepath = null; // should be used on outmost router
export let pagebase = null;
export let path = "";
export let redirect = null;
export let exact = null;
export let hashbang = false;
export let notfound = null;
export let forceslash = null;
import { setContext, getContext, onDestroy, tick, onMount } from "svelte";
let getParentPath = getContext(key);
let handledExact = false;
let params = {};
let matched = false;
let parentPath = getParentPath ? getParentPath() : "";
let matchedCallback;
let onExitCallback;
let current;
path = basepath ? (basepath + "/" + path).replace(/\/{2,}/, "/") : path != null ? (parentPath + "/" + path).replace(/\/{2,}/, "/") : "";
setContext(key, () => {
  if (parentPath && !path.startsWith(parentPath)) path = (parentPath + "/" + path).replace(/\/{2,}/, "/");
  return path.replace(/\*/, "");
});
if (path != null) {
  const onMatched = async (ctx, next) => {
    current = page.current;
    if (redirect) {
      navigate(redirect); //using page.redirct flashes, no idea why?
    } else if (forceslash != null && ctx.path && !ctx.path.match(/\/$/)) {
      // no slash at end, redirect to one with
      navigate(ctx.path + "/");
    } else {
      params = ctx.params;
      matched = true;
      if (!notfound && !basepath) {
        ctx.handledExact = current.startsWith(ctx.path);
      }
      ctx.handled = true;
      next();
    }
    handledExact = ctx.handledExact;
  };
  const onExit = (ctx, next) => {
    matched = false;
    next();
  };
  onMount(() => {
    let route = exact ? path : path.replace(/\/$/, "") + "*";
    matchedCallback = new page.Route(route).middleware(onMatched);
    page.callbacks.push(matchedCallback);
    onExitCallback = new page.Route(route).middleware(onExit);
    page.exits.push(onExit);
    page.start({
      hashbang: hashbang
    });
    pagebase && page.base(pagebase);
    page.redirect(page.current); // recall to updated nested
  });
  onDestroy(() => {
    page.callbacks = page.callbacks.filter(mw => mw != matchedCallback);
    page.exits = page.exits.filter(mw => mw != onExitCallback);
    if (basepath) {
      page.stop();
    }
  });
} else {
  matched = true;
}</script>

{#if matched && !notfound}
  <slot {params} route={current.replace(/\?.+/,"")} />
{/if}

{#if matched && notfound && !handledExact}
  <slot {params} route={current} />
{/if}