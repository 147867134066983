<script>// drop files for upload
import { createEventDispatcher, onMount, tick } from 'svelte';
export let style = null; //"border:1px dashed #ccc;height:100%;width:100%;"
let className = "";
export { className as class }; // creates a `class` property, even though it is a reserved word
export let clickable = false;
export let types = null;
let files = [];
let randInputName = "";
let frame;
onMount(async () => {
  randInputName = `input${Math.random() * 100000}upload_${Math.random() * 100000}`;
  if (!["relative", "absolute", "fixed"].includes(frame.style.position)) {
    frame.style.position = "relative";
  }
});
const dispatch = createEventDispatcher();
const handleDrop = e => {
  e.preventDefault();
  if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
    files = filterTypes(e.dataTransfer.files);
  }
  dispatch('updated', {
    files
  });
};
const handleClick = e => {
  if (e.target && e.target.files) {
    files = filterTypes(Array.from(e.target.files));
  }
  dispatch('updated', {
    files
  });
};
const filterTypes = droppedfiles => {
  if (!types || !types.length) {
    return Array.from(droppedfiles);
  }
  return Array.from(droppedfiles).filter(f => {
    if (types.includes(f.type)) {
      return true;
    } else {
      dispatch('error', {
        message: "That type of file cannot be dropped here, only " + types
      });
      console.warn("can't drop", f.type);
    }
  });
};
const handleDragOver = e => {
  e.preventDefault();
};</script>
<div {style} class={className} on:drop={handleDrop} on:dragover={handleDragOver} bind:this={frame}>
  {#if clickable != false }<label for={randInputName}></label><input id={randInputName} type="file" on:change={handleClick} >{/if}
  <slot {files}></slot>
</div>

<style>
  label{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0,0,0,0.001);
    cursor:pointer;
  } 
  input[type=file]{
    opacity:0;
    position:absolute;
    top:0
  }
</style>