<script>import { Grid, Search, Formrow, api, IconSpinner } from "duo-kit";
let data = [],
  filtered = [],
  selected = [],
  loading = false,
  dropFilter = "";
async function load() {
  loading = true;
  try {
    data = (await api.load({
      url: `/duocms/api/nwcabstracts`
    })).data;
    data = data.map(row, idx => Object.assign({}, {
      id: idx
    }, row));
  } catch (err) {}
  loading = false;
}
load();
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function filterRows() {
  if (!dropFilter || !filtered || !filtered.length) return filtered;
  let esc_query = escapeRegExp(dropFilter);
  let searches = new RegExp("^(?=.*" + esc_query.replace(/\s/, ")(?=.*") + ").*$", "i");
  return filtered.filter(item => JSON.stringify(item).match(searches));
}
$: results = filterRows(dropFilter, filtered);</script>

{#if loading}
  <div class="alert alert-info">
    <h4><IconSpinner />  Loading Abstracts</h4>
  </div>
{:else}
  <div style="height:300px;margin-bottom:50px;">
    <div class="row">
      <div class="col-xs-8">
        <Search bind:filtered={filtered} bind:data={data} placeholder="Type to search" label="Search"/>
      </div>
      <div class="col-xs-4">
        <Formrow type="select" bind:value={dropFilter}>
          <option value="">All presentations</option>
          <option value="Automotive">Automotive</option>
          <option value="Multiphysics">Multiphysics</option>
          <option value="Simulation Data Management">Simulation Data Management</option>
          <option value="Systems Engineering">Systems Engineering</option>
        </Formrow>
      </div> 
    </div>
    <Grid 
      data={results} 
      bind:selected={selected} 
      rowheight={55} 
      columns={[
        {label:"Presentation Title",field:"Presentation Title",width:10},
        {label:"Presenter Name",field:"Presenter Name",width:3},
        {label:"Presenter Company",field:"Presenter Company",width:5}
      ]}
    >
      <div slot="row" let:item>
        <div class="cell" style="flex:10;white-space:pre-wrap;text-overflow: ellipsis;height:60px;">{item["Presentation Title"]}</div>
        <div class="cell" style="flex:3">{item["Presenter Name"]}</div>
        <div class="cell" style="flex:5">{item["Presenter Company"]}</div>
      </div>
      <div slot="footer" let:data>{data.length} Presentations</div>
    </Grid>
  </div>

  {#if selected.length}
    <div class="panel panel-default">
      <div class="panel-heading">
        <button class="close" aria-label="close details" on:click={()=> selected = []}><span class="fa fa-close"></span></button>
        <h3 class="panel-title">Details</h3>
        
      </div>
      <div class="panel-body">
        <dl class="dl-horizontal">
          {#each Object.keys(selected[0]) as key}
            {#if key!="Topics"}
              <dt>{key}</dt> 
              <dd class="pre">{selected[0][key]}</dd>
              <br>
            {/if}
          {/each}
        </dl>
      </div>
    </div>
  {/if}
{/if}