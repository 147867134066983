<script>import { Loadbar, Route, api } from "duo-kit";
import { onMount } from "svelte";
import Team from "./team.svelte";
import Techareas from "./techareas.svelte";
import Competencies from "./competencies.svelte";
import Reporting from "./reporting.svelte";
import Certification from "./certification.svelte";
import Settings from "./settings.svelte";
import Exporter from "./exporter.svelte";
import { resolve } from "path";
import { session } from "./store.js";
export let showlogin;
session.load();
if (showlogin) api.registerLogin(showlogin);

// below hacks into riot login to re-open login, the resolve once login is complete
onMount(() => {
  api.registerLogin(async () => {
    return new Promise((resolve, reject) => {
      let loginbar = document.querySelector('loginbar');
      if (loginbar && loginbar._tag) {
        loginbar._tag.loggedin = () => resolve();
        loginbar._tag.tags.loginmodal.show();
      }
    });
  });
});
let inCms = window.location.search.match(/cms/);
let openid = null;</script>

{#if inCms}
  <h1>Tracker</h1>
  <p>Sorry the PSE Tracker does not work in CMS editmode, please use  the cog opposite and choose 'Hide Editor'</p>
{:else}
  <div class="loadbar"><Loadbar /></div>
  <Route hashbang={true} pagebase="/professional-development/tracker/" let:route let:params>
    <ul class="nav nav-tabs">
      <li role="presentation" class:active={route && route == `/`}><a href="#!/">Welcome</a></li>
      <li role="presentation" class:active={route && route.startsWith(`/competencies`)}><a href="#!/competencies/">Competency Tracker</a></li>
      <li role="presentation" class:active={route && route.startsWith(`/reporting`)}><a href="#!/reporting/">Reporting</a></li>
      <li role="presentation" class:active={route && route.startsWith(`/pseteams`)}><a href="#!/pseteams/">PSE Teams</a></li>
      <li role="presentation" class:active={route && route.startsWith(`/certification`)}><a href="#!/certification/">PSE Certification</a></li>
      <li role="presentation" class:active={route && route.startsWith(`/settings`)}><a href="#!/settings/">PSE Settings</a></li>
      {#if $session && $session.permissions && $session.permissions.includes("users_all")}
        <li role="presentation" class:active={route && route.startsWith(`/export`)}><a href="#!/export/">Import / Export</a></li>
      {/if}
    </ul>
  </Route>

  

  <Route path="" exact>
    <h2>Welcome to the PSE (Professional Simulation Engineer) Competency Tracker</h2>
    
    <p>The PSE Competency Tracker is an online competence management system for simulation engineers. The PSE Competency Tracker allows individuals to plan and monitor their development as a simulation engineer, tracking competences throughout their career. Companies can do the same for their staff, using the PSE Competency Tracker to create and manage a database of the combined simulation skills of their workforce.</p>
    <p>The PSE Competency Tracker provides:</p>
    <ul>
      <li>a reference Competence Framework for Simulation Engineers</li>
      <li>a guide to useful Educational Resources</li>
      <li>an online Competence Management System</li>
    </ul>
    <h3>PSE Competences</h3>
    <p>
      Contained within the PSE Competency Tracker, PSE Competences are a comprehensive list of standard competences for the Analysis and Simulation industry, developed and maintained by NAFEMS. Encompassing thousands of statements, the PSE competences are subdivided into several technical areas. For each area and statement of competence, a list of suggested educational resources is provided.The PSE Competences:
    </p>

    <ul>
      <li>
        offer a guide to self-learners in Analysis and Simulation;
      </li>
      <li>
        provide a useful resource for education and course development;
      </li>
      <li>
      	provide a standard set of competence statements.
      </li>
    </ul>
  </Route>

  <Route path="competencies" exact>
    <Techareas bind:openid={openid}/>
  </Route>

  <Route path="competencies/:id" let:params let:route>
    <Competencies id={params.id} compid={params.compid} {route} />
  </Route>

  <Route path="reporting" let:route>
    <Reporting {route} />
  </Route>


  <Route path="pseteams">
    <Team />
  </Route>

  <Route path="certification">
    <Certification />
  </Route>

  <Route path="settings">
    <Settings />
  </Route>

  <Route path="export">
    <Exporter />
  </Route>
  
{/if}
  
  
<style>
  .nav-tabs li{
    margin-bottom:-2px;
  }
  .loadbar{
    position:absolute;
    top:-15px;
    left:-15px;
    right:-15px;
  }

</style>
