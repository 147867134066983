<script>import { Button, Modal, Formrow, api, Grid, Search, IconSpinner } from "duo-kit";
import { navigate } from "duo-kit";
import { createEventDispatcher } from "svelte";
import { trackerusers } from "./store";
export let id = null;
let dispatch = createEventDispatcher();
let open = true;
let selected = [];
let filtered = [];
let loading = false;
trackerusers.load();
async function save() {
  let user_ids = selected.map(user => user.id);
  let team_id = id;
  await api.save({
    url: `/duocms/api/tracker/teamusers/${team_id}`,
    method: "put",
    data: user_ids
  });
  dispatch("saved");
  open = false;
}

// async function load(){
//   team = (await api.load({url:`/duocms/api/tracker/teams/${id}`})).data
// }
// if(id) load()</script>
<Modal bind:open heading="Add Team Members" on:closed={()=> navigate("/pseteams/")} style="width:90%;max-width:800px;">
  <div class="row">
    {#if loading}
      <p><IconSpinner />  Loading Users...</p>
    {:else}
      <div class="searchbox">
        <Search placeholder="Search Users" bind:data={$trackerusers} bind:filtered={filtered} />
      </div>
      <div style="height:400px">
        <Grid 
          rowheight={30} data={filtered} 
          bind:selected
          multiselect
          columns={[
            {label:"First Name",field:"first_name",width:5},
            {label:"Surname",field:"surname",width:5},
            {label:"Email",field:"email",width:5}
          ]}
        >
          <div slot="footer" let:data>
            {filtered.length!=$trackerusers.length ? filtered.length+" of " : ""}{$trackerusers.length} Users
          </div>
        </Grid>
      </div>
    {/if}
  </div>
  <div slot="toolbar">
    <div class="pull-right">
      <Button mode="default" on:click={()=> open = false}>Cancel</Button>
      <Button mode="primary" on:click={save} disabled={!selected.length}>Add Selected ({selected.length})</Button>
    </div>
  </div>
</Modal>