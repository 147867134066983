<script>import { Grid, api, Dateformat } from "duo-kit";
let selected = [];
let comp_selected = [];
let report = [];
let techareas = [];
let competencies = [];
let users = [];
async function load() {
  techareas = (await api.load({
    url: "/duocms/api/tracker/technicalareas"
  })).data;
  selected = [techareas[0]];
  showCompetencies();
}
async function showCompetencies() {
  comp_selected = [];
  let id = selected[0].id;
  let res = (await api.load({
    url: `/duocms/api/tracker/technicalareas/${id}`
  })).data;
  comp_selected = [res];
  competencies = [res];
  competencies = competencies.concat(res.competencies);
  showUsers(id);
}
async function showUsers(id) {
  if (comp_selected.length) id = comp_selected[0].id;
  users = (await api.load({
    url: `/duocms/api/tracker/reports/technicalareas/${id}`
  })).data;
}
load();</script>

<div class="row">
  <div class="col-xs-12 col-md-3">
    <h3>Technical Areas</h3>
    <div style="height:500px">
      <Grid 
        rowheight={30} data={techareas} 
        bind:selected
        on:click={showCompetencies}
        columns={[
          {label:"Code",field:"code",width:5}
        ]}
      >
      <div slot="footer" let:data>
        {data.length} Technical Areas
      </div>
    </Grid>
    </div>
    <!-- {#if selected.length}
      <div class="panel panel-default" style="margin-top:10px;font-size:12px;height:240px;">
        <div class="panel-heading" style="font-size:14px;padding:10px;">Technical Area</div>
        <table class="table table-striped">
          <tbody>
            <tr><th>Code</th><td>{selected[0].code}</td></tr>
            <tr><th>Title</th><td>{selected[0].title}</td></tr>
          </tbody>
        </table>
      </div>
    {/if} -->
  </div>
  <div class="col-xs-12 col-md-9">
    <h3>Competencies Claimed</h3>
    <div style="height:210px">
      <Grid 
        rowheight={30} data={competencies}
        bind:selected={comp_selected}
        on:click={showUsers}
        columns={[
          {label:"Area",field:"area",width:2},
          {label:"Code",field:"code",width:1},
          {label:"Statement",field:"statement",width:10}
        ]}
      > 
        <div slot="row" let:item>
          <div class="cell" style="flex:2" >{item.area || "Technical Area"}</div>
          <div class="cell" style="flex:1" >{item.code}</div>
          <div class="cell" style="flex:10;white-space:pre-wrap;text-overflow: ellipsis;height:90px;overflow:hidden;" >{item.area ? item.statement : item.title}</div>
        </div> 
        <div slot="footer" let:data>
          {data.length} Competencies
        </div>
      </Grid>
    </div>

    <h3>{"Users achieving " + ( comp_selected.length ? comp_selected[0].code+" Competence" : selected.length ? selected[0].code+" Technical Area" : "")}</h3>
    <div style="height:220px">
      <Grid 
        rowheight={30} data={users} 
        columns={[
          {label:"First Name",field:"area",width:2},
          {label:"Surname",field:"code",width:2},
          {label:"Achieved",field:"statement",width:1},
          {label:"Date Issues",field:"statement",width:2}
        ]}
      > 
        <div slot="row" let:item>
          <div class="cell" style="flex:2" >{item.first_name}</div>
          <div class="cell" style="flex:2" >{item.surname}</div>
          <div class="cell" style="flex:1;text-align:center" >{#if item.achieved}<span class="fa fa-check" style="color:green" />{:else}<span class="fa fa-times" style="color:#888" />{/if}</div>
          <div class="cell" style="flex:2" >{#if item.issued_at}<Dateformat format="dd/MM/yyyy" date={item.issued_at} />{/if}</div>
        </div> 
        <div slot="footer" let:data>
          {data.length} Users
        </div>
      </Grid>
    </div>
  </div>

</div>