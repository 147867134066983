<script>import { api, Formrow, Button, Alert, toasterStore, IconSpinner, IconSearch } from "duo-kit";
import AddressSearch from "./address-search.svelte";
import { createEventDispatcher } from "svelte";
export let id = "me";
export let user = {};
export let tools = true;
let countries = [];
let states = [];
let loading = false;
let customAddress = false;
let dispatch = createEventDispatcher();
async function load() {
  loading = true;
  countries = (await api.load({
    url: `/duocms/api/countries`
  })).data;
  states = (await api.load({
    url: `/duocms/api/states`
  })).data;
  if (id == "me") {
    user = (await api.load({
      url: `/duocms/api/users/me`
    })).data;
  } else if (id) {
    user = (await api.load({
      url: `/duocms/api/team/${id}`
    })).data;
  } else {
    console.log("No user id", id);
  }
  loading = false;
}
async function save() {
  user.id = id;
  try {
    await checkaddress();
    if (id == "me") {
      delete user.id;
      user = (await api.save({
        url: `/duocms/api/users/me`,
        data: user
      })).data;
      user.id = "me";
    } else if (id) {
      user = (await api.save({
        url: `/duocms/api/team/${id}`,
        data: user
      })).data;
    } else {
      user = (await api.save({
        url: `/duocms/api/team/`,
        data: user
      })).data;
    }
    toasterStore.add({
      type: "success",
      title: "Saved Successfully",
      message: "Your details have been updated successfully"
    });
    dispatch("saved");
  } catch (err) {
    toasterStore.add({
      type: "danger",
      title: "Sorry!",
      message: err.message
    });
  }
}
function countryFromCode(code) {
  if (!code) return "";
  let country = countries.find(c => c.code == code);
  return country ? country.name : "";
}
function stateFromCode(code) {
  if (!code) return "";
  let state = states.find(c => c.code == code);
  return state ? state.name : "";
}
async function checkaddress() {
  let data = {
    address: user.address,
    city: user.city,
    state: user.state,
    postcode: user.postcode,
    country: user.country
  };
  let res;
  try {
    res = (await api.save({
      url: `/duocms/api/melissa/confirm`,
      data: data
    })).data;
  } catch (e) {
    console.error(e);
    throw new Error("Could not verrify address. Please try again later.");
  }
  if (res.TotalRecords > 0) {
    let code = res.Records[0].Results;
    if (code.includes("AV13")) {
      throw new Error("We've found your address location, please check the building name or number and try again.");
    }
    if (code.includes("AV12") || code.includes("AV11") || code.includes("AV14")) {
      throw new Error("Your address doesn't contain enough information to be a full postall address. Please check your details and try again.");
    }
    if (code.match(/ae/i)) {
      // general error
      throw new Error("The address you have supplied could not be validated. Please check your details and try again.");
    }
    if (code.includes("AE05")) {
      // more specific errors
      throw new Error("You have supplied incomplete address information, which identifies more than one location. Please check your details and try again.");
    }
  } else {
    throw new Error("The address you have supplied could not be validated. Please check your details and try again.");
  }
}
$: load(id);</script>
{#if loading}
  <div class="loading">
    <div class="spinner"><div><IconSpinner />  Loading...</div></div>
  </div>
{/if}

<form disabled={loading} on:submit|preventDefault={save} >
  <Formrow type="text" label="First Name" id="first_name" bind:value={user.first_name} />
  <Formrow type="text" label="Surname" id="surname" bind:value={user.surname} />
  <Formrow type="email" label="Email" id="email" bind:value={user.email} />
  <Formrow type="text" label="Telephone" id="phone1" bind:value={user.phone1} />
  <Formrow type="text" label="Mobile" id="phone2" bind:value={user.phone2} />


  <Formrow label="Address Search">
    <AddressSearch bind:custom={customAddress} bind:user={user} countries={countries} />
  </Formrow>


  {#if customAddress}
    <Formrow label="Address" id="address" type="textarea" bind:value={user.address} />
    <Formrow label="City" id="city" type="text" bind:value={user.city} />
    {#if user.country=="US"}
      <Formrow type="select" label="State" id="country" bind:value={user.state}>
        <option value="">Select US State</option>
        {#each states as state}
          <option value={state.code}>{state.name}</option>
        {/each}
      </Formrow> 
    {/if}
    <Formrow label="Postcode" id="postcode" type="text" bind:value={user.postcode} />
    <Formrow type="select" label="Country" required id="country" bind:value={user.country}>
      <option value="">Select Country</option>
      {#each countries as country}
        <option value={country.code}>{country.name}</option>
      {/each}
    </Formrow>

  {:else}
    <div class="addresswrap">
      <Formrow label="Address" id="address">
        <div class="address">{user.address||""}</div>
      </Formrow>
      <Formrow type="static" label="City" id="city" bind:value={user.city} />
      {#if user.country=="US"}
        <Formrow type="static" label="State" required id="state" value={user.state ? stateFromCode(user.state) : ""} />
      {/if}

      <Formrow type="static" label="Postcode" id="postcode" value={user.postcode || ""} />
      <Formrow type="static" label="Country" required id="country" value={user.country ? countryFromCode(user.country) : ""} />
    </div>
  {/if}

  
  <div class="tools">
    {#if user.student_membership}
      <Alert type="info">Student {user.student_membership} Account</Alert>
    {/if}
    {#if tools}
      <Button mode="primary" type="submit">Save Changes</Button>
    {/if}
  </div>
</form>


<style>
  form{
    --s-primary:#2d2e4e;
  }
  /*desktop*/
  @media (min-width: 600px){
    form{
      --s-labelwidth:140px;
    }
  }
  /* mobile */
  .tools{
    margin-left:calc( var(--s-labelwidth) + 12px );
    margin-right:8px;
  }

  @media (max-width: 599px){
    .addresswrap :global(.form-row .form-input-static),.addresswrap .address{
      padding-inline:3px;
    }
  }

  .addresswrap{
    --s-formrow-margins:0;
  }

  .address{
    white-space:pre-wrap;
    padding:8px;
  }

  .loading{
    position:relative;
  }

  .spinner{
    position:absolute;
    bottom:5px;
    right:5px;
    padding:10px 20px;
    background:white;
    display:grid;
    place-items: center;
    box-shadow:0 0 10px rgba(0,0,0,0.1);
  }

</style>