<script>import { IconAdd, api } from "duo-kit";
export let openid = null;
import { sections, session } from "./store.js";
let tracker_options = {};
function toggleOpen(id) {
  openid = openid == id ? null : id;
}
async function load() {
  await session.load();
  await sections.load();
  tracker_options = $session.tracker_options || {};
}
load();</script>

<h1>Technical Areas</h1>
{#if $sections && $session}
  {#each $sections as row}
    {#if (row.title!="COGAN Core Area" || tracker_options.cogan) && 
         (row.title!="FEMForging" || tracker_options.femforging) && 
         (row.title!="Pressure & Power Systems Industry" || tracker_options.pressurepower) 
    }
      <div class="pseblock" class:active={ row.id == openid}>
        <h3>{row.title}</h3>
        <button class="opener" on:click={()=> toggleOpen(row.id)}><IconAdd /></button>
        {#each row.technicalareas as child}
          <a class="linkblock" href={"#!/competencies/"+child.id}>
            <h4>{child.title}</h4>
          </a>
        {/each}
      </div>
    {/if}
  {/each}
{/if}


<style>
  .pseblock{
    background:#444;
    color:white;
    padding:5px;
    margin:10px 0;
    position:relative;
    overflow:hidden;
  }
  /* start at 2 because loadbar is also a div */
  .pseblock:nth-of-type(2){
    background-color:#EC0D0D;
  }
  .pseblock:nth-of-type(3){
    background-color:#FF8300;
  }
  .pseblock:nth-of-type(4){
    background-color:#273647;
  }
  .pseblock:nth-of-type(5){
    background-color:#5E748D;
  }
  .pseblock:nth-of-type(6){
    background-color:#45C3EE;
  }
  .pseblock:nth-of-type(7){
    background-color:#4B688A;
  }
  .pseblock:nth-of-type(8){
    background-color:#00acd0;
  }
  .pseblock:nth-of-type(9){
    background-color:#80007f;
  }
  .pseblock:nth-of-type(10){
    background-color:#607f79;
  }
  .pseblock:nth-of-type(11){
    background-color:#05A8AA;
  }
  .pseblock:nth-of-type(12){
    background-color:#6369D1;
  }
  .pseblock:nth-of-type(13){
    background-color:#6369D1;
  }
  .pseblock:nth-of-type(14){
    background-color:#60E1E0;
  }

  .pseblock > h3 {
    margin:0;
    padding:12px 5px 15px 5px;
  }

  .pseblock a{
    display:block;
    background:rgba(255,255,255,0.3);
    padding:0 10px;
    margin:0px 2px;
    color:white;
    transition: all 0.3s;
    max-height:0px;
    overflow:hidden;
  }

  .pseblock.active a{
    padding:10px 10px;
    margin:2px;
    max-height:100px;
  }
  .pseblock a:hover{
    text-decoration:none;
    background:rgba(0,0,0,0.1);
  }

  .pseblock a h4{
    margin:3px 0;
  }

  .opener{
    position:absolute;
    top:0px;
    right:0px; 
    left:0px;
    padding:15px;
    width:100%;
    color:white;
    fill:rgba(255,255,255,0.8);
    background:none;
    border:0px;
    transition: fill 0.2s;
  }

  .opener :global(.d-icon){
    display:block;
  }

  .opener:hover{
    fill:rgba(255,255,255,1);
  }

  .opener:focus {
    outline:0px;
  }

  .pseblock.active .opener :global(svg){
    transform:rotateZ(45deg)
  }

  .opener :global(svg){
    width:25px;
    height:25px;
    float:right;
    transition: transform 0.1s;
  }

  


</style>
