<script>import Popover from "./popover.svelte";
import Button from "./button.svelte";
import { createEventDispatcher } from 'svelte';
export let title = "Are you sure?";
export let target = "";
export let canceltext = "Cancel";
export let confirmtext = "Delete";
export let placement = "top";
export let confirmmode = "danger";
export let cancelmode = "default";
export let mode = "";
export let size = "";
export let disabled = false;
export let ariaLabel = null;
let className = "";
export { className as class };
let open = false;
const dispatch = createEventDispatcher();
const handleConfirm = e => {
  e.preventDefault();
  dispatch('confirm');
  open = false;
};
const close = e => {
  e.preventDefault();
  e.stopPropagation();
  open = false;
};</script>

<Popover title={title} {placement} bind:open={open} class={className} {mode} {size} {disabled} {target} {ariaLabel}>
  <slot />
  <div slot="content">
    <div class="duo-toolbar">
      <Button mode={cancelmode} on:click={close}>{canceltext}</Button>
      <Button mode="{confirmmode}" on:click={handleConfirm}>{confirmtext}</Button>
    </div>
  </div>
</Popover>

<style>
.duo-toolbar{
  display:flex;
  justify-items: stretch;
  margin:0 -8px;
}
.duo-toolbar > :global(*){
  flex:1;
  margin:0 5px;
}
</style>