<script>import { afterUpdate } from "svelte";
import { toasterStore, IconCancel } from "../../index.js";
import CircleTimer from "./circletimer.svelte";
import Portal from "./portal.svelte";
import { slide } from 'svelte/transition';</script>

<Portal zindex={1070}>
  <div class="toaster">
    {#each $toasterStore as slice (slice)}
      <div class="slice {slice.type}" transition:slide>
        <button class="cancel" on:click={()=> toasterStore.remove(slice)}><CircleTimer duration={slice.ms ? slice.ms/1000 : 3}><IconCancel /></CircleTimer></button>
        <h3>{slice.title}</h3>
        <p>{slice.message}</p>
      </div>
    {/each}
  </div>
</Portal>

<style>
  .toaster{
    z-index:10002;
    position: fixed;
    top: 5px;
    right:10px;
  }
  .slice{
    width:250px;
    padding:15px 20px;
    border-radius:4px;
    box-shadow:0 2px 10px rgba(0,0,0,0.3);
    margin:5px 0;
    position:relative;
  }
  .cancel{
    position:absolute;
    top:5px;
    right:5px;
    background:transparent;
    border:0;
    color:white;
    width:34px;
    height:34px;
    cursor:pointer;
    transition: transform 0.3s;
  }
  .cancel:hover{
    transform:scale(1.3);
  }
  .slice h3{
    margin:0 0 10px 0;
    color:white;
  }
  /* backgrounds set twice, first for IE11 */
  .slice.primary,.slice.info,.slice.success,.slice.warning,.slice.danger,.slice.dark { color:white; fill:white;}
  .slice.primary {background-color:hsla(var(--themehue), 50%, 35%, 1)}
  .slice.info {background-color:#17a2b8;background-color:var(--s-info,#17a2b8)}
  .slice.success {background-color:#28a745;background-color:var(--s-success,#28a745)}
  .slice.warning {background-color:#ffc107;background-color:var(--s-warning,#ffc107)}
  .slice.danger {background-color:#dc3545;background-color:var(--s-danger,#dc3545)}
  .slice.dark {background-color:#343a40;background-color:var(--s-dark,#343a40)}

</style>
