<script>import { api, IconTick } from "duo-kit";
export let completed, total;
async function markComplete() {
  let path = window.location.pathname;
  await api.save({
    url: "/duocms/api/training/progress",
    data: {
      url: "",
      path,
      type: "complete"
    }
  });
  completed++;
}</script>
<div class="alert alert-success">
  {#if completed > total}
    <p>Section Completed</p>
    <IconTick />
  {:else if total == completed}
    <p>Have you completed this unit? Then mark this unit as completed.</p>
    <button class="btn btn-success btn-sm pull-right" on:click={markComplete}>Mark as Completed</button>
  {:else}
    <p>Complete the {total} tasks above then click to mark this page as complete</p>
    <button class="btn btn-success btn-sm pull-right" disabled>Mark as Completed</button>
  {/if}
</div>

<style>
  .alert{
    display:grid;
    grid-template-columns:1fr auto;
  }

</style>