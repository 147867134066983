<script>import { Formrow, Formdate, Button, toasterStore, api } from "duo-kit";
import { createEventDispatcher } from "svelte";
export let id = null;
export let userid = "me";
const dispatch = createEventDispatcher();
let competency = {};
async function findCompetency(id) {
  // load single here?
  competency = (await api.load({
    url: `/duocms/api/tracker/record/${id}/${userid}`
  })).data || {};
}
async function save() {
  competency.tracker_competency_id = id;
  competency = (await api.save({
    url: `/duocms/api/tracker/record`,
    data: competency
  })).data;
  toasterStore.add({
    title: "Saved",
    type: "success",
    message: "Saved Successfully"
  });
  dispatch("saved", competency);
}
$: findCompetency(id);</script>

<Formrow labelwidth={180} label="Method of Achievment" type="select" bind:value={competency.method}>
  <option value={null}>Please Choose</option>
  <option value="Self evaluation">Self evaluation</option>
  <option value="Independent evaluation">Independent evaluation</option>
  <option value="Line manager evaluation">Line manager evaluation</option>
 </Formrow>
 <Formdate labelwidth={180} label="Date" id="date" bind:value={competency.issued_at} utc={true} />
 <Formrow labelwidth={180} label="Comments" type="textarea" bind:value={competency.comments} />
 <Formrow labelwidth={180} label="Achieved" type="checkbox" id="active" bind:checked={competency.achieved} />
 <div class="pull-right">
  <Button mode="primary" on:click={save}>Save Changes</Button>
</div>