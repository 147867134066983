<script>import { Grid, api, Search, Dateformat, IconDownload, Button, toasterStore } from "duo-kit";
import { trackerusers, session } from "./store";
let selected = [];
let filtered = [];
let user;
let showmessage;
let editComments = false;
async function sessionLoaded() {
  await trackerusers.load();
  if (!$trackerusers.length) {
    selected = [$session];
    showUser();
  } else {
    // can see multiple, must be admin
    editComments = true;
  }
}
async function showUser() {
  user = (await api.load({
    url: `/duocms/api/tracker/pse/${selected[0].id}`
  })).data;
  if (!user) showmessage = true;
}
async function updateComments() {
  await api.save({
    url: `/duocms/api/tracker/pse/${user.id}`,
    data: {
      user_id: user.id,
      comments: user.comments
    }
  });
  toasterStore.add({
    title: "Saved",
    type: "success",
    message: "Your comments have been updated successfully"
  });
}
function download() {
  window.location.href = "/duocms/api/tracker/users?csv=true";
}
session.load();
$: $session.id && sessionLoaded();</script>
<h1>PSE Certification</h1>
<div class="row">
  {#if $trackerusers.length}
    <Button on:click={download} ><IconDownload /> Download CSV</Button>
    <div class="searchbox">
      <Search placeholder="Search Users" bind:data={$trackerusers} bind:filtered={filtered} />
    </div>
    <div style="height:400px">
      <Grid 
        rowheight={30} data={filtered} 
        bind:selected
        on:click={showUser}
        columns={[
          {label:"First Name",field:"first_name",width:3},
          {label:"Surname",field:"surname",width:3},
          {label:"Email",field:"email",width:5},
          {label:"PSE Status",field:"status",width:2},
          {label:"Valid To",field:"valid_to",width:2},
        ]}
      >
        <div slot="row" let:item>
          <div class="cell" style="flex:3" >{item.first_name}</div>
          <div class="cell" style="flex:3" >{item.surname}</div>
          <div class="cell" style="flex:5" >{item.email}</div>
          <div class="cell" style="flex:2" >{item.status || ""}</div>
          <div class="cell" style="flex:2" >{#if item.valid_to}<Dateformat date={item.valid_to}/>{/if}</div>
        </div> 
        <div slot="footer" let:data>
          {filtered.length!=$trackerusers.length ? filtered.length+" of " : ""}{$trackerusers.length} Users
        </div>
      </Grid>
    </div>
  {/if}

  {#if user}
    <div class="panel panel-default" style="margin-top:10px;">
      <div class="panel-heading" style="padding:10px;">Certification Details</div>
      <div class="panel-body">
        <dl class="row">
          <dt class="col-sm-3">Name</dt><dd class="col-sm-9">{user.first_name} {user.surname}</dd>
          <dt class="col-sm-3">Status</dt><dd class="col-sm-9">{user.status}</dd>
          {#if user.level}<dt class="col-sm-3">Level</dt><dd class="col-sm-9">{user.level || ""} {#if user.pseplus && user.pseplus.match(/pass/)} (PSE +){/if}</dd>{/if}
          {#if user.valid_to}<dt class="col-sm-3">Valid To</dt><dd class="col-sm-9"><Dateformat date={user.valid_to}/></dd>{/if}
          {#if user.pseplus}<dt class="col-sm-3">PSE +</dt><dd class="col-sm-9">{user.pseplus}</dd>{/if}
        </dl>
      </div>
    </div>
    {#if user.awards && user.awards.length}
      <div class="panel panel-default" style="margin-top:10px;">
        <div class="panel-heading" style="padding:10px;">PSE Awards</div>
        <table class="table">
          <thead>
            <tr><th>Award</th><th>Level</th></tr>
          </thead>
          <tbody>
            {#each user.awards as award}
              <tr><td>{award.title}</td><td>{award.level}</td></tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}
    <div class="panel panel-default" style="margin-top:10px;">
      <div class="panel-heading" style="padding:10px;">Comments</div>
      {#if editComments}
        <textarea class="commentsbox" placeholder="Enter Comments Here" rows="5" bind:value={user.comments} />
        <div class="panel-body" style="text-align:right;">
          <Button mode="primary" on:click={updateComments}>Save Comments</Button>
        </div>

      {:else}
        <div class="panel-body pre">
          {user.comments || "No Comments Available"}
        </div>
      {/if}
    </div>
  {/if}

  {#if !user && showmessage}
    <p>Sorry, you don't appear to have a certification record.</p>
  {/if}
</div>



<style>
  .row{
    position:relative;
  }
  .searchbox{
    position: absolute;
    top:-1px;
    right:-5px;
  }
  .commentsbox{
    margin:5px 5px 5px 5px;
    border:1px solid #ccc;
    padding:10px;
    resize:none;
    display:block;
    width:calc( 100% - 10px);
  }
</style>