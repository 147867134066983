<script>import { IconAdd, Modal, Formrow, Button } from "duo-kit";
let value = "";
let open = false,
  elem;
let trim, color, print, bind, paper, finish, linen, foil;
let trimsizes = [{
  label: '4.25" x 6.875 - Pocketbook"',
  value: '0425X0687'
}, {
  label: '5.5" x 8.5" - Digest',
  value: '0550X0850'
}, {
  label: '5.83" x 8.27" - A5',
  value: '0583X0827'
}, {
  label: '6" x 9" - US Trade',
  value: '0600X0900'
}, {
  label: '6.14" x 9.21" Royal',
  value: '0614X0921'
}, {
  label: '6.63" x 10.25" Comic',
  value: '0663X1025'
}, {
  label: '7.5" x 7.5" Small Square',
  value: '0750X0750'
}, {
  label: '7" x 10" Executive',
  value: '0700X1000'
}, {
  label: '7.44" x 9.68" Crown Quatro',
  value: '0744X0968'
}, {
  label: '8.5" x 8.5" Square',
  value: '0850X0850'
}, {
  label: '8.27" x 11.69" A4',
  value: '0827X1169'
}, {
  label: '8.5" x 11" US Letter',
  value: '0850X1100'
}, {
  label: '9" x 7" Landscape',
  value: '0900X0700'
}, {
  label: '11" x 8.5" US Letter Landscape',
  value: '1100X0850'
}, {
  label: '11.69" X 8.27" A4 Landscape',
  value: '1169X0827'
}];
let colortypes = [{
  label: "Mono",
  value: "BW"
}, {
  label: "Color",
  value: "FC"
}];
let printtypes = [{
  label: "Premium",
  value: "PRE"
}, {
  label: "Standard",
  value: "STD"
}];
let bindtypes = [{
  label: "Perfect",
  value: "PB"
}, {
  label: "Coil",
  value: "CO"
}, {
  label: "Saddle Stitch",
  value: "SS"
}, {
  label: "Case Wrap",
  value: "CW"
}, {
  label: "Linen Wrap",
  value: "LW"
}, {
  label: "Wire O",
  value: "WO"
}];
let papertypes = [{
  label: "60# Uncoated White",
  value: "060UW444"
}, {
  label: "60# Uncoated Cream",
  value: "060UC444"
}, {
  label: "70# Coated White",
  value: "070CW460"
}, {
  label: "80# Coated White",
  value: "080CW444"
}, {
  label: "100# Coated White",
  value: "100CW200"
}];
let finishtypes = [{
  label: "Gloss",
  value: "G"
}, {
  label: "Matte",
  value: "M"
}, {
  label: "Unlaminated",
  value: "U"
}];
let linentypes = [{
  label: "Red Linen",
  value: "R"
}, {
  label: "Navy Linen",
  value: "N"
}, {
  label: "Black Linen",
  value: "B"
}, {
  label: "Gray Linen",
  value: "G"
}, {
  label: "Tan Linen",
  value: "T"
}, {
  label: "Forest Linen",
  value: "F"
}, {
  label: "Interior Cover Print",
  value: "I"
}, {
  label: "N/A",
  value: "X"
}];
let foiltypes = [{
  label: "Gold",
  value: "G"
}, {
  label: "Black",
  value: "B"
}, {
  label: "White",
  value: "W"
}, {
  label: "N/A",
  value: "X"
}];
function openPicker() {
  open = true;
  let slotContent = elem.getRootNode().querySelector('slot').assignedNodes()[0];
  if (slotContent.tagName == "INPUT") {
    value = slotContent.value;
    parseValue(value);
  }
}
function parseValue(value) {
  var _trimsizes$find, _colortypes$find, _printtypes$find, _bindtypes$find, _papertypes$find, _finishtypes$find, _linentypes$find, _foiltypes$find;
  trim = (_trimsizes$find = trimsizes.find(item => value.startsWith(item.value))) === null || _trimsizes$find === void 0 ? void 0 : _trimsizes$find.value;
  color = (_colortypes$find = colortypes.find(item => value.substr(9, 2) == item.value)) === null || _colortypes$find === void 0 ? void 0 : _colortypes$find.value;
  print = (_printtypes$find = printtypes.find(item => value.substr(11, 3) == item.value)) === null || _printtypes$find === void 0 ? void 0 : _printtypes$find.value;
  bind = (_bindtypes$find = bindtypes.find(item => value.substr(14, 2) == item.value)) === null || _bindtypes$find === void 0 ? void 0 : _bindtypes$find.value;
  paper = (_papertypes$find = papertypes.find(item => value.substr(16, 8) == item.value)) === null || _papertypes$find === void 0 ? void 0 : _papertypes$find.value;
  finish = (_finishtypes$find = finishtypes.find(item => value.substr(24, 1) == item.value)) === null || _finishtypes$find === void 0 ? void 0 : _finishtypes$find.value;
  linen = (_linentypes$find = linentypes.find(item => value.substr(25, 1) == item.value)) === null || _linentypes$find === void 0 ? void 0 : _linentypes$find.value;
  foil = (_foiltypes$find = foiltypes.find(item => value.substr(26, 1) == item.value)) === null || _foiltypes$find === void 0 ? void 0 : _foiltypes$find.value;
}

// 0850X0850FCPRECW080CW444MXX
function close() {
  open = false;
}
function update() {
  let slotContent = elem.getRootNode().querySelector('slot').assignedNodes()[0];
  if (slotContent.tagName == "INPUT") {
    slotContent.setAttribute("value", value);
  }
  close();
}
$: value = (trim || "         ") + (color || "  ") + (print || "   ") + (bind || "  ") + (paper || "        ") + (finish || " ") + (linen || " ") + (foil || " ");</script>

  <div class="input-group input-group-sm" bind:this={elem}>
    <slot class="inner" />
    <div class="input-group-btn"> 
      <button class="btn btn-primary" on:click={openPicker}><IconAdd /></button> 
    </div> 
  </div>

  <Modal bind:open={open} heading="Lulu Printing Specification" style="width:500px;--s-labelwidth:120px;">
    <form on:submit|preventDefault={update}>
      <Formrow label="Size" type="select" required bind:value={trim}>
        <option value="">Please Select</option>
        {#each trimsizes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Colour" type="select" required bind:value={color}>
        <option value="">Please Select</option>
        {#each colortypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Print" type="select" required bind:value={print}>
        <option value="">Please Select</option>
        {#each printtypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Bind" type="select" required bind:value={bind}>
        <option value="">Please Select</option>
        {#each bindtypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Paper" type="select" required bind:value={paper}>
        <option value="">Please Select</option>
        {#each papertypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Finish" type="select" required bind:value={finish}>
        <option value="">Please Select</option>
        {#each finishtypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Linen" type="select" required bind:value={linen}>
        <option value="">Please Select</option>
        {#each linentypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <Formrow label="Foil" type="select" required bind:value={foil}>
        <option value="">Please Select</option>
        {#each foiltypes as item}<option value={item.value}>{item.label}</option> {/each}
      </Formrow>

      <br>
      <Formrow label="pod_package_id" type="text" value={value} on:blur={(e)=> parseValue(e.target.value)} />
    

      <div style="text-align:right">
        <Button mode="default" type="button" on:click={close}>Close</Button>
        <Button mode="primary" type="submit">Update</Button>
      </div>
    </form>
  
  </Modal>