<script>export let type = null;
export let disabled = null;
export let name = "";
export let id = null;
export let value = "";
export let group = "";
export let checked = false;
export let required = null;
export let rows = 3;
export let label = "";
export let fixed = false;
export let autocomplete = null;
export let autofocus = null;
export let labelwidth = "150px";
export let maxitems = 5; // auto complete
export let valueField = "id";
export let labelField = "name";
export let placeholder = "";
export let items = [];
export let style = "";
export let vertical = null;
export let list = null;
export let indeterminate = null;
export let minlength = null,
  maxlength = null,
  pattern = null,
  min = null,
  max = null;
export let input = null;
export let mode = null;
export let note = null;
export let step = null;
export let readonly = null;
import TypeAhead from "./typeahead.svelte";
import Alertwrap from "./alertwrap.svelte";
let taValue = "";
const SLOTS = $$slots;
const handleInput = event => {
  value = event.target.value;
};
if (("" + labelwidth).match(/^[0-9]+$/))
  // if it's just a number add px
  labelwidth += "px";</script>
<Alertwrap mode={mode}>
  <div class={"form-row"+(vertical ? " vertical" : "")+(type == "checkbox" ? " checkrow" : "")  + (SLOTS && SLOTS.suffix ? " hasSuffix" : "") + (SLOTS && SLOTS.prefix ? " hasPrefix" : "")} style={style} >
    
    {#if type == "checkbox"}
        <input bind:this={input} style="margin-left:calc(var(--s-labelwidth,{vertical ? "0" : parseInt(labelwidth,10)}px) + 9px );" type="checkbox" {id} {step} {readonly} {autofocus} {autocomplete} {placeholder} {indeterminate} disabled={disabled || fixed} {required} name={name} bind:checked={checked} on:change on:focus on:blur on:keydown />
        {#if label || (SLOTS && SLOTS.label)}
          <label class="checkboxLabel" for={id || name} >{#if SLOTS && SLOTS.label}<slot name="label"></slot>{:else}{label}{/if}</label>
        {/if}
    {/if}

    {#if type == "radio"}
        <input bind:this={input} style="margin-left:calc(var(--s-labelwidth,{vertical ? "0" : parseInt(labelwidth,10)}px) + 9px );" type="radio" {id} {step} {readonly} {autofocus} {autocomplete} {placeholder} {indeterminate} disabled={disabled || fixed} {required} value={value} name={name} bind:group={group} on:change on:focus on:blur on:keydown />
        {#if label || (SLOTS && SLOTS.label)}
          <label class="checkboxLabel" for={id || name} >{#if SLOTS && SLOTS.label}<slot name="label"></slot>{:else}{label}{/if}</label>
        {/if}
    {/if}

    {#if type != "checkbox" && type != "radio"}
      {#if label || (SLOTS && SLOTS.label)}
        <label class="slabel" style="width:var(--s-labelwidth,{vertical ? "100%": labelwidth});" for={id || name} >{#if SLOTS && SLOTS.label}<slot name="label"></slot>{:else}{label}{/if}</label>
      {/if}
      {#if SLOTS && SLOTS.prefix}
        <slot name="prefix" />
      {/if}
      {#if fixed}
        <div class="form-input-static">{value}</div>
      {:else if type == "textarea"}
          <textarea bind:this={input} type="text" {id} class="form-input" {readonly} {autofocus} {autocomplete} disabled={disabled} {placeholder} {rows} {required} {minlength} {maxlength} {pattern} name={name} bind:value={value} on:change on:focus on:blur on:keydown></textarea>
      {:else if type == "select"}
          <select bind:this={input} type="text" {id} class="form-input" {readonly} {autofocus} {autocomplete} disabled={disabled} {required} name={name} bind:value={value} on:change on:focus on:blur on:keydown>
            <slot></slot>
          </select>
      {:else if type == "static"}
        <div class="form-input-static">{value}</div>
      {:else if type == "typeahead"}
        <TypeAhead bind:items={items} labelFieldName={labelField} bind:value={value} valueFieldName={valueField} maxItemsToShowInList={maxitems} />
      {:else if type!=null}
        <input bind:this={input} type={type} {id} class={["checkbox","radio","range"].includes(type) ? "" : "form-input"} {step} {readonly} {autofocus} {autocomplete} {placeholder} {list} {minlength} {maxlength} {min} {max} {pattern} disabled={disabled} {required} name={name} value={value} on:input="{handleInput}" on:input on:change on:focus on:blur on:keydown />
      {:else}
        <div class="slotwrap">
          <slot />
        </div>
      {/if}
    {/if}
    <slot name="suffix" />
  </div>
  {#if note || (SLOTS && SLOTS.note)}
    <div class="note" style={"--s-labelwidth:calc("+( vertical ? "0" : parseInt(labelwidth,10) )+"px + 7px )"}><slot name="note">{note}</slot></div>
  {/if}
</Alertwrap>

<style>
  .form-row.vertical{
    display:flex;
    flex-wrap: wrap;
  }
  
  .form-row{
    display:flex;
    margin:var(--s-formrow-margins,5px);
    box-sizing: border-box;
    align-items:flex-start;
    align-items: center;
  }

  .checkrow{
    align-items:center;
  }

  .form-row :global(:has(.stars)){
    align-self: center
  }

  .form-row * {
    box-sizing: border-box;
  }

  .form-row label{
    text-align:right;
    line-height:1.8em;
    padding:0 8px 0 0;
  }

  .form-row.vertical label{
    text-align:left;
    margin:0;
    padding:0 0 0 0;
  }

  .form-row :global(.form-input){
    flex:3;
    padding:5px 8px;
    font-size:14px;
    box-sizing: border-box;
  }
  .slotwrap{
    flex:3;
  }

  .form-row .checkboxLabel{
    padding:0;
    margin:2px 3px;
    text-align:left;
  }
  .form-row :global([slot="suffix"] .sbtn){
    border-radius:0 var(--s-border-radius,4px) var(--s-border-radius,4px) 0;
    margin:0;
  }
  
  .form-row.hasSuffix :global([slot="suffix"] > :not(button)),
  .form-row.hasPrefix :global([slot="prefix"] > :not(button)){
    display:flex;
    padding: 6px 12px; 
    border: 1px solid #ccc;
    height:34px;
    align-items: center;
    box-sizing: border-box;
  }

  .form-row.hasSuffix :global([slot="suffix"] > div.dropdown){
    padding:0; 
    border:0;
  }

  .form-row.hasSuffix :global([slot="suffix"] > :not(button)){
    border-radius:0 var(--s-border-radius,4px) var(--s-border-radius,4px) 0;
  }
  .form-row.hasPrefix :global([slot="prefix"] > :not(button)){
    border-radius:var(--s-border-radius,4px) 0 0 var(--s-border-radius,4px);
  }

  .form-row :global([slot="prefix"] .sbtn){
    border-radius:var(--s-border-radius,4px) 0 0 var(--s-border-radius,4px);
    margin:0;
  }

  .form-row :global(input.form-input),
  .form-row :global(select.form-input),
  .form-row :global(textarea.form-input),
  .form-row :global(div.form-input){
    font-weight:normal;
    border-radius:var(--s-border-radius,4px);
    display: block;
    width: 100%;
    font-size: 14px;
    font-family:inherit;
    line-height: 1.428571429;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    margin: 0;
    height:34px;
    padding: 4px 8px 5px 8px;
  }
  /* add own dropdown arrow */
  .form-row :global(select.form-input){
    background: white url("data:image/svg+xml;utf8,<svg fill='currentColor' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z' fill='grey' /><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 50%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    padding: 4px 25px 5px 8px;
  }

  .form-row :global(input.form-input[disabled]),
  .form-row :global(select.form-input[disabled]),
  .form-row :global(textarea.form-input[disabled]),
  .form-row :global(div.form-input[disabled]){
    color:#999;
    background-color:rgba(0,0,0,0.03);
    cursor:not-allowed;
  }

  .form-row :global(.form-input-static){
    display:block;
    padding: 0 8px;
  }

  .form-row :global(textarea.form-input),
  .form-row :global(div.form-input){
    height:auto;
  }

  .form-row.hasSuffix :global(input.form-input){
    border-radius:var(--s-border-radius,4px) 0 0 var(--s-border-radius,4px);
    border-right:0px;
  }

  .form-row.hasPrefix :global(input.form-input){
    border-radius:0 var(--s-border-radius,4px) var(--s-border-radius,4px) 0;
    border-left:0px;
  }

  .form-row.hasPrefix.hasSuffix :global(input.form-input){
    border-radius:0;
    border-left:0px;
    border-right:0px;
  }

  .form-row :global(input[type=range]){
    flex:3;
    display:block;
  }

  .form-row :global(input[type=color]){
    padding:0;
    height:35px;
    width:40px;
    flex:none;
  }

  .form-row :global(input[type="checkbox"]),.form-row :global(input[type="radio"]){
    width:auto;
    border:0;
    margin: 0px 5px 0 5px;
    display:inline-block;
  }

  .checkboxLabel{
    padding: 0;
    margin: 3px 0;
    text-align: left;
  }

  .note{
    font-size:0.8em;
    margin-left:var(--s-labelwidth);
    padding:0 0 8px 0;
  }


  /* Mobile, put lable on line above */
  @media only screen and (max-width: 600px) {
    .form-row:not(.checkrow){
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .form-row .slabel{
      text-align:left;
      margin:0;
      width:100%!important;
      padding:1px 2px;
    }

    .note{
      margin-left:5px;
    }

    .note :global(a){
      color:inherit;
    }
  }
  
</style>