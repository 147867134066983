<script>import { Button, api, toasterStore } from "duo-kit";
export let userid = null;
let user = null;
let saving;
let added = [];
let removed = [];
let membership = [];
let communities = [{
  name: "Computational Fluid Dynamics Community",
  forum_id: [59, 57]
}, {
  name: "Computational Structural Mechanics Community",
  forum_id: [843, 844, 846]
}, {
  name: "Engineering Data Science Community",
  forum_id: [1129, 1130]
}, {
  name: "High Performance Computing Community",
  forum_id: []
}, {
  name: "Impact, Shock & Crash Community",
  forum_id: [1123, 1124]
}, {
  name: "Manufacturing Process Simulation Community",
  forum_id: [95, 94]
}, {
  name: "Multibody Dynamics Community",
  forum_id: [75, 421]
}, {
  name: "Multiphysics Community",
  forum_id: [609, 610]
}, {
  name: "Optimisation Community",
  forum_id: [23, 40, 41]
}, {
  name: "Simulation Data Management Community",
  forum_id: [2311]
}, {
  name: "Stochastics Community",
  forum_id: [140]
}, {
  name: "Systems Modeling & Simulation Community",
  forum_id: [106, 424, 1050]
}];
function toggle(event, comm) {
  console.log(comm);
  if (event.target.checked) {
    if (removed.find(entry => comm.forum_id.includes(entry))) {
      removed = removed.filter(entry => !comm.forum_id.includes(entry));
    } else {
      added = added.concat(comm.forum_id);
    }
    membership = membership.concat(comm.name);
  } else {
    if (added.find(entry => comm.forum_id.includes(entry))) {
      added = added.filter(entry => !comm.forum_id.includes(entry));
    } else {
      removed = removed.concat(comm.forum_id);
    }
    membership = membership.filter(entry => entry != comm.name);
  }
}
async function load() {
  user = (await api.load({
    url: "/duocms/api/users/me"
  })).data;
  membership = user.technical_communities.split(";").filter(entry => entry && entry != "");
}
async function save() {
  saving = true;
  let technical_communities = membership.join(";");
  user = await api.save({
    url: "/duocms/api/users/me",
    data: {
      technical_communities
    }
  });
  // subscribe user to correct forums?
  // need to work out added & removed, the post added to 
  if (added.length) {
    for (let i = 0; i < added.length; i++) {
      if (added[i]) await api.save({
        url: `/duocms/api/forums-watched/${added[i]}`,
        method: "put"
      });
    }
    added = [];
  }
  if (removed.length) {
    for (let i = 0; i < removed.length; i++) {
      if (removed[i]) await api.remove({
        url: `/duocms/api/nafems/forums-watched/${removed[i]}`
      });
    }
    removed = [];
  }
  saving = false;
  toasterStore.add({
    title: "Thank you",
    message: "For updating your community memberships. Your preferences have been saved, and you'll hear from your new communities shortly.",
    type: "success"
  });
}
if (userid) load();</script>

{#if !userid}
  <div class="alert alert-info"><slot>To update your technical community preferences please login above.</slot></div>
{:else}
  <table class="table table-striped">
    <thead>
      <tr><th style="text-align:center" class="col-sm-2">Member</th><th>Community Name</th></tr>
    </thead>
    <tbody>
      {#each communities as comm }
        <tr>
          <td style="text-align:center"><input type="checkbox" checked={membership.includes(comm.name)} on:click={(e)=> toggle(e,comm)} /></td>
          <td>{comm.name}</td>
        </tr>
      {/each}
    </tbody>
  </table>
  <Button mode="primary" on:click={save} disabled={saving}>{#if saving} <span class="fa fa-refresh fa-spin" /> Saving {:else} Save Changes {/if}</Button>
{/if}


