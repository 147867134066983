<script>import { Button, Formrow, api, Alert, IconSpinner } from "duo-kit";
export let userid; // use to determine if logged in
export let firstname, surname, email, eventid;
let checking,
  booked = false,
  register = {},
  checkemail = false,
  disabled = false;
async function handleRegister() {
  disabled = true;
  try {
    await api.save({
      url: `/duocms/api/register`,
      data: register
    });
    checkemail = true;
  } catch (err) {}
  disabled = false;
}
$: userid && eventid && checkBooking();</script>
    {#if checkemail}
      <Alert mode="success"><strong>IMPORTANT</strong> - We've sent you an email. You need to click the link in the email to confirm your registration.</Alert>
    {:else}
      <form on:submit|preventDefault={handleRegister}>
        <Formrow label="Email" disabled={disabled} required type="email" name="email" bind:value={register.email} />
        <Formrow label="First Name" disabled={disabled} required type="text" name="first_name" bind:value={register.first_name} />
        <Formrow label="Surname" disabled={disabled} required type="text" name="surname" bind:value={register.surname} />
        <p class="notice">By submitting your details, you consent to us using your information in accordance with our 
          <a href="/about-us/legal/privacy/" target="_blank">privacy policy.</a></p>
        <div style="text-align:right">  
          <Button mode="primary" type="submit" disabled={disabled}>
            {#if disabled}<IconSpinner /> {/if} Sign Up
          </Button>
        </div>
      </form>
    {/if}
    


<style>
  form{
    --s-primary:#2d2e4e;
    --s-labelwidth:100px;
    --s-formrow-margins:0px;
  }
  .notice{
    margin-left:105px;
    font-size:11px;
    text-align:right;
  }
  @media only screen and (max-width: 600px){
    .notice{
      margin-left:0px;
    }
  }


</style>