<script>import { Formrow, Button, IconTick } from "duo-kit";
let copied = false;
let product = {
  name: "",
  currency: "GBP",
  price: "10",
  vat: false
};
function copy() {
  let link = `${window.location.protocol}//${window.location.host}/duocms/api/nafems/makepayment?${new URLSearchParams(product)}`;
  navigator.clipboard.writeText(link);
  copied = true;
  setTimeout(() => {
    copied = false;
  }, 4000);
}</script>

<form method="GET" action="/duocms/api/nafems/makepayment" target="_blank">
  <Formrow name="name" label="Purchase Name" type="text" required bind:value={product.name} />
  <Formrow name="currency" label="Currency" type="select" required bind:value={product.currency}>
    <option value="GBP">£ (GBP)</option>
    <option value="USD">$ (USD)</option>
    <option value="EUR">€ (EUR)</option>
  </Formrow>
  <Formrow name="price" label="Price" type="text" placeholder="0.00" required bind:value={product.price} />
  <Formrow name="vat" id="vat" label="VAT" type="checkbox" bind:checked={product.vat} />
  <Button mode="primary" type="submit">Test Link</Button>

  {#if product.name && product.price}
    <br>
    <div class="well">
      {window.location.protocol}//{window.location.host}/duocms/api/nafems/makepayment?{new URLSearchParams(product)}
    </div>
    <Button on:click={copy}>Copy Link
      {#if copied} <IconTick />{/if}
    </Button>
    {#if copied}<p>
      Link copied to your clipboard, you can now paste it into an email.
    </p>
    {/if}
  {/if}
</form>