<script>import Modal from "../shared/modal.svelte";
import api from "../shared/api";
import Button from "../shared/button.svelte";
import Formrow from "../shared/formrow.svelte";
import toasterStore from "../shared/toaster-store.js";
export let open = false;
export let postid;
let message = "";
async function sendflag() {
  await api.save({
    url: `/duocms/api/forums-flag/${postid}`,
    data: {
      message,
      url: window.location.href
    }
  });
  toasterStore.add({
    type: "success",
    title: "Message Sent",
    message: "Your message has been sent. Thank you for your feedback."
  });
  message = "";
  postid = null;
}</script>

<Modal bind:open={open} heading="Flag Post" style="width:500px;">
  <p>Please indicated below the reason for flagging this post.</p>
  <Formrow type="textarea" bind:value={message} labelwidth={70} rows="5" label="Reason" />

  <div slot="toolbar" style="text-align:right">
    <Button on:click={()=> postid = null}>Close</Button>
    <Button disabled={!message.length} mode="primary" on:click={sendflag}>Send</Button>
  </div>
</Modal>