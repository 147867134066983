<script>export let mode = null;
import Alert from "./alert.svelte";</script>
{#if mode}
  <div class="alertwrap">
    <Alert mode={mode}>
      <slot />
    </Alert>
  </div>
{:else}
  <slot />
{/if}

<style>
  .alertwrap{
    margin:5px 0;
  }
  .alertwrap :global(.salert){
    padding:0;
    --s-formrow-margins:5px;
  }
</style>