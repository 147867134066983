<script>import { Button, Formrow, api, Alert, IconSpinner } from "duo-kit";
export let userid; // use to determine if logged in
export let firstname, surname, email, eventid;
let checking,
  booked = false,
  register = {},
  checkemail = false,
  disabled = false;
async function checkBooking() {
  checking = true;
  try {
    let res = await api.load({
      url: `/duocms/api/salesforce/myevents/${eventid}`
    });
    console.log(res);
    if (res && res.data && res.data.registered) {
      booked = true;
      console.log({
        booked
      });
    }
  } catch (err) {}
  checking = false;
}
async function bookEvent() {
  try {
    await api.save({
      url: `/duocms/api/salesforce/myevents/${eventid}`,
      data: {}
    });
    await checkBooking();
  } catch (err) {
    console.log(err);
  }
}
function triggerLogin() {
  let loginbar = document.querySelector('loginbar');
  if (loginbar && loginbar._tag) {
    // riot 
    if (loginbar._tag.redir) loginbar._tag.redir = null;
    loginbar._tag.tags.loginmodal.show();
  }
}
async function handleRegister() {
  register.event_id = eventid;
  disabled = true;
  try {
    await api.save({
      url: `/duocms/api/nafems/freebooking`,
      data: register
    });
    checkemail = true;
  } catch (err) {}
  disabled = false;
}
$: userid && eventid && checkBooking();</script>

<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">Register for Free</h3>
  </div>
  <div class="panel-body">
    {#if userid && !checking && !booked}
      <Formrow type="static" label="First Name" value={firstname}></Formrow>
      <Formrow type="static" label="Surname" value={surname}></Formrow>
      <Formrow type="static" label="Email" value={email}></Formrow>
      <div style="text-align:right">
        <Button mode="primary" on:click={bookEvent} >Book Now!</Button>
      </div>
    {:else if checking}
      <Alert mode="info">Checking your booking...</Alert>
    {:else if booked}
      <Alert mode="success">You are booked onto this event</Alert>
    {:else if checkemail}
      <Alert mode="success"><strong>IMPORTANT</strong> - We've sent you an email. You need to click the link in the email to confirm your registration.</Alert>
    {:else}
      <form on:submit|preventDefault={handleRegister}>
        <Formrow label="Email" disabled={disabled} required type="email" name="email" bind:value={register.email} />
        <Formrow label="First Name" disabled={disabled} required type="text" name="first_name" bind:value={register.first_name} />
        <Formrow label="Surname" disabled={disabled} required type="text" name="surname" bind:value={register.surname} />
        <p class="notice">By submitting your details, you consent to us using your information in accordance with our 
          <a href="/about-us/legal/privacy/" target="_blank">privacy policy.</a></p>
        <div style="text-align:right">  
          <Button mode="default" on:click={triggerLogin}>Login to Book</Button>
          or
          <Button mode="primary" type="submit" disabled={disabled}>
            {#if disabled}<IconSpinner /> {/if} Book via Email
          </Button>
        </div>
      </form>
    {/if}
    
  </div>
</div>

<style>
  .panel{
    --s-primary:#2d2e4e;
    --s-labelwidth:100px;
    --s-formrow-margins:0px;
  }
  .panel :global(.sbtn.primary){
    transition:background-color 0.3s;
  }

  .panel :global(.sbtn.primary:hover){
    background-color: #c00;
    border: 1px solid #c00;
  }
  .notice{
    margin-left:105px;
    font-size:11px;
    text-align:right;
  }
  @media only screen and (max-width: 600px){
    .notice{
      margin-left:0px;
    }
  }


</style>