<script>export let src = "";
// frame wrapper for 3D model
$: name = src.match(/[^\/]+$/) ? src.match(/[^\/]+$/)[0] : "";
$: framesrc = `/glance/index.html?name=${name}&url=${src}`;</script>
<div class="viewer">
  {#if window.location.search.match(/editmode=/)}
    <div class="mask">
      <h4>3d Viewer for {name}</h4>
      <p>disabled while editing</p>
    </div>
  {:else}
    <iframe src={framesrc} frameborder="0"></iframe>
  {/if}
</div>

<style>
  .viewer{
    width:100%;
    height:100%;
    background:#444;
    display:flex;
    position: relative;
  }
  iframe{
    flex:1;
    width:100%;
    height:100%;
  }
  .mask{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 25%, rgba(200,200,200,0.1) 25%, rgba(200,200,200,0.1) 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, rgba(200,200,200,0.1) 75%, rgba(200,200,200,0.1) 100%);
    background-size: 56.57px 56.57px;
    color:white;
    display:grid;
    place-content: center;
    text-align:center;
  }
</style>