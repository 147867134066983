<script>import { Button, Modal, Formrow, api } from "duo-kit";
import { navigate } from "duo-kit";
import { teams } from "./store.js";
export let id = null;
let open = true;
let team = {
  name: "",
  description: ""
};
async function save() {
  team = (await api.save({
    url: "/duocms/api/tracker/teams",
    data: team
  })).data;
  console.log({
    team
  });
  teams.update(team);
  open = false;
}
async function load() {
  team = (await api.load({
    url: `/duocms/api/tracker/teams/${id}`
  })).data;
}
if (id) load();</script>
<Modal bind:open heading={id ? "Update Team" : "Add New Team"} on:closed={()=> navigate("/pseteams/")} style="width:90%;max-width:400px;">
  <Formrow label="Name" type="text" bind:value={team.name} labelwidth={100} />
  <Formrow label="Description" type="textarea" bind:value={team.description} labelwidth={100} placeholder="optional" rows="5" />
  <div slot="toolbar">
    <div class="pull-right">
      <Button mode="default" on:click={()=> open = null }>Close</Button>
      <Button mode="primary" on:click={save} >Save</Button>
    </div>
  </div>
</Modal>