<script>import IconSearch from "../icons/search.svelte";
import IconSpinner from "../icons/spinner.svelte";
import Formrow from "../shared/formrow.svelte";
import Button from "../shared/button.svelte";
import Modal from "../shared/modal.svelte";
import Markdown from "../shared/markdown.svelte";
import * as api from "../shared/api.js";
import { createEventDispatcher } from "svelte";
export let parentid = null;
let keywords = "";
let searchResults = [];
let showSearchResults = false;
let loading = false;
let dispatch = createEventDispatcher();
async function handleSearch(e) {
  e.preventDefault();
  loading = true;
  try {
    searchResults = (await api.load({
      url: `/duocms/api/forums-search?parent_id=${parentid}&keywords=${keywords}`
    })).data;
  } catch (err) {}
  loading = false;
  showSearchResults = true;
}
function openResult(id) {
  showSearchResults = false;
  dispatch("selected", id);
}</script>


<form class="search" on:submit={handleSearch}>
  <Formrow id="search" type="input" placeholder="Search Forum" bind:value={keywords}>
    <div slot="suffix">
      <Button mode="primary" type="submit" disabled={loading || !keywords}>
        {#if loading}<IconSpinner /> {:else} <IconSearch />{/if}
      </Button>
    </div>
  </Formrow>
</form>

<Modal bind:open={showSearchResults} heading="Search Results" style="width:500px;">
  {#if !searchResults.length}
    <p>Sorry, we haven't found anything matching your search, please try again</p>
  {:else}
    <ol>
    {#each searchResults as post}
      <li>
        <h5 on:click={()=> openResult(post.title ? post.id : post.parent_id)}>{post.title || post.parent_title+" (reply)"}</h5>
      </li>
      <hr>
    {/each}
    </ol>
{/if}

</Modal>



<style>
  @media(min-width:800px){
    .search{
      width:200px;
      position:absolute;
      top:0px;
      right:0;
    }
  }
  h5{
    cursor:pointer;
    font-weight:bold;
  }
  h5:hover{
    text-decoration:underline;
  }

</style>