<script>import { api, Alert, Button, Progress, IconTick, IconCancel, IconArrowRight, IconArrowLeft } from "duo-kit";
import { tick } from "svelte";
import { fade, slide, fly } from 'svelte/transition';
export let id = null;
let quiz = {};
let question_idx = 0;
let answers = {};
let question;
async function load(id) {
  quiz = (await api.load({
    url: `/duocms/api/quizzes/${id}`
  })).data;
  answers = {};
  quiz.questions.forEach(q => answers[q.id] = q.myanswer);
  setQuestion(question_idx);
}
async function setQuestion(idx) {
  question_idx = idx;
  question = null;
  await tick();
  question = quiz.questions[idx];
}
function triggerGlobalEvent() {
  let event = new CustomEvent('quizcomplete', {
    detail: quiz
  });
  window.dispatchEvent(event);
}
$: id && load(id);
$: quiz.mymarks == quiz.marks && triggerGlobalEvent();
async function check() {
  quiz = (await api.save({
    url: `/duocms/api/quizzes/${id}/answers`,
    data: {
      answers
    }
  })).data;
}
async function resetQuiz() {
  await api.remove({
    url: `/duocms/api/quizzes/${id}/myanswers?_csrf=${window.csrf}`
  });
  question_idx = 0;
  load(id);
}</script>
{#if !id}
  <Alert mode="info">
    <p>Please select / add a quiz in the toolbar</p>
  </Alert>
{:else if quiz.id}
  <div class="quiz">
    <div class="quiz_pager">
      {#each quiz.questions as q,idx}
        <button on:click={()=> setQuestion(idx)} class:correct={q.mymarks > 0} class:incorrect={!q.mymarks && q.myanswer!=null} class:active={question_idx==idx} title="Quesition {idx+1}">
          {#if q.mymarks>0}
            <IconTick />
          {:else if q.myanswer!=null}
            <IconCancel />
          {/if}
        </button>
      {/each}
    </div>

    {#if question}
      <div in:fade={{ delay: 50, duration: 300 }} out:fade={{duration: 300 }} key={question_idx} >
        <h3>Question {question_idx+1}</h3>
        <p class="quiz_question pre" in:fly={{ delay: 250, duration: 150, y: -100 }} out:fly={{ duration: 300, y:100 }}>{question.question}</p>
        <div class="quiz_answers" in:fly={{ delay: 500, duration: 150, y: -200 }} out:fly={{ duration: 300, y:200}}>
          {#each question.options as option,idx}
            <button class:active={answers[question.id]==idx} on:click={()=> answers[question.id]=idx}>{option}</button>
          {/each}
        </div>
      </div>
    {/if}

    <div class="toolbar">
      <Button mode="primary" on:click={check}>Check Answers</Button>
      <Button mode="default" disabled={question_idx==0} on:click={()=> setQuestion(question_idx-1)}><IconArrowLeft /></Button>
      <Button mode="default" disabled={question_idx>=quiz.questions.length} on:click={()=> setQuestion(question_idx+1)}><IconArrowRight /></Button>
      <div class="quiz_score">
        <Progress mode="success" min="0" max={quiz.marks} value={quiz.mymarks}><strong>{quiz.mymarks} / {quiz.marks}</strong></Progress>
      </div>
    </div>

    <button class="quiz_reset" on:click={resetQuiz}>Reset</button>
    
  </div>
{/if}


<style>
  .quiz{
    min-height:40px;
    --s-primary:#283748;
    --s-button-margins:0;
    --s-border-radius:0;
    padding:0 20px 20px;
    background:#4c698a10;
    border:1px solid #4c698a20;
    border-radius:4px;
  }

  .quiz_pager{
    display:flex;
    gap:8px;
    margin:-10px auto 20px auto;
    width: max-content;
  }

  .quiz_pager button{
    all:unset;
    border:1px solid rgb(76 102 138 / 20%);
    box-shadow:0 0 8px rgb(76 102 138 / 20%);
    width:18px;
    height:18px;
    background:white;
    border-radius: 100px;
    cursor: pointer;
    box-shadow:0 0 0 2px #fff;
    display:inline-flex;
    font-size:10px;
    align-items: center;
    justify-content: center;
  }

  .quiz_pager button:hover{
    box-shadow:0 0 0 1px #283748;
  }

  .quiz_pager button.active{
    box-shadow:0 0 0 2px #283748;
  }

  .quiz_pager button.correct{
    border:1px solid #28a745;
    background:#28a745;
    color:white;
  }

  .quiz_pager button.incorrect{
    border:1px solid #dd3300;
    background:#dd3300;
    color:white;
  }

  .quiz_pager button :global(.d-icon){
    top:0.1em;
  }

  .quiz_question{
    padding:30px 50px;
    background:white;
    box-shadow:0 0 8px rgb(76 102 138 / 20%);
    color:#283748;
    font-weight:bold;
    font-size:16px;
  }

  .quiz_answers{
    display:flex;
    flex-direction: column;
    gap:5px;
  }

  .quiz_answers button{
    all:unset;
    padding:8px 12px;
    transition: 0.3s background-color,0.2s color;
    background:white;
    box-shadow:0 0 5px rgb(76 102 138 / 20%)
  }

  .quiz_answers button:hover{
    background:#4c698a50;
  }

  .quiz_answers button.active{
    background:#283748;
    color:white;
  }

  .quiz .toolbar{
    display:flex;
    margin:30px 0 0 0;
    gap:15px;
    align-items: center;
  }

  .quiz_score{
    min-width:300px;
    flex:1;
  }

  .quiz_score :global(.sbar){
    line-height:24px;
    height:24px;
    font-size:14px;
  }

  .quiz_reset{
    all:unset;
    text-decoration: underline;
    cursor:pointer;
    float:right;
    font-size:12px;
  }

</style>