<script>import { Progress, IconSpinner, api } from "duo-kit";
export let path = "",
  data,
  loading,
  total,
  completed;
async function load() {
  if (total == null) {
    loading = true;
    let qs = new URLSearchParams({
      path: path,
      section: true
    });
    let res = await api.load({
      url: `/duocms/api/training/progress?${qs}`
    });
    data = res.data;
    loading = false;
  } else {
    data = {
      total,
      completed
    }; // use attributes
  }
}
$: path && load();</script>

<div class="training" class:editing={window.location.search.match(/editmode=true/)}>
  {#if loading}
    <small><IconSpinner/> Loading...</small>
  {:else if data && data.total > 0}
    <Progress mode="success" value={data.completed} max={data.total} style="height:10px;"></Progress>
    <small>{Math.round((100/data.total) * data.completed)}% Completed</small>
  {:else if !path}
    <small>Training Progress - No path</small>
  {:else if data.total == 0}
    <small>No Tasks</small>
  {:else}
    <small>Log in to view progress</small>
  {/if}
</div>

<style>
  .training :global(.sbar){
    height:8px;
  }
  .editing{
    background:rgba(0,0,0,0.1);
    outline:1px dotted #444;
  }
</style>